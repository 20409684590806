@import '~component-lib/swatches';
@import '~scss/components';

.v2-categories-education-common {
  &--heading {
    @include activity-card-heading-bg-img("~component-lib/activity-artwork/category-education.svg");
  }

  &--footer-existing {
    background-color: $purple;
  }
}

.v2-categories-competition-common {
  &--heading {
    @include activity-card-heading-bg-img("~component-lib/activity-artwork/category-competition.svg");
  }

  &--footer-existing {
    background-color: $yellow;
  }
}

.v2-categories-employment-common {
  &--heading {
    @include activity-card-heading-bg-img("~component-lib/activity-artwork/category-employment.svg");
  }

  &--footer-existing {
    background-color: $blue;
  }
}
