@import '~component-lib/swatches';

.color-new-gray     { color: $new-gray;     }

.color-white        { color: $white;        }

.color-red          { color: $red;          }
.color-red-d        { color: $red-d;        }

.color-orange-xl    { color: $orange-xl;    }
.color-orange-l     { color: $orange-l;     }
.color-orange       { color: $orange;       }
.color-orange-d     { color: $orange-d;     }

.color-yellow-l     { color: $yellow-l;     }
.color-yellow       { color: $yellow;       }
.color-yellow-d     { color: $yellow-d;     }

.color-purple-xl    { color: $purple-xl;    }
.color-purple-l     { color: $purple-l;     }
.color-purple       { color: $purple;       }
.color-purple-d     { color: $purple-d;     }

.color-blue-xxl     { color: $blue-xxl;     }
.color-blue-xl      { color: $blue-xl;      }
.color-blue-l       { color: $blue-l;       }
.color-blue         { color: $blue;         }
.color-blue-d       { color: $blue-d;       }

.color-bluegrey-xxl { color: $bluegrey-xxl; }
.color-bluegrey-xl  { color: $bluegrey-xl;  }
.color-bluegrey-l   { color: $bluegrey-l;   }
.color-bluegrey     { color: $bluegrey;     }
.color-bluegrey-d   { color: $bluegrey-d;   }

.color-green-l      { color: $green-l;      }
.color-green        { color: $green;        }
.color-green-d      { color: $green-d;      }

.color-grey-xl      { color: $grey-xl;      }
.color-grey-l       { color: $grey-l;       }
.color-grey         { color: $grey;         }
.color-grey-d       { color: $grey-d;       }

// Branded colors
.color-wsu-red-l    { color: $wsu-red-l;    }
.color-wsu-red      { color: $wsu-red;      }
.color-wsu-red-d    { color: $wsu-red-d;    }
