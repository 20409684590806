@import '~css/v2/abstracts/functions/get-input-functions';
@import '~css/v2/abstracts/functions/get-color-functions';
/**
* checkbox:
* 1. placeholder class for checkbox
* 2. placeholder class for checkbox label
* 3. placeholder class for checkbox message (div below input)
*/

%checkbox {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;

  &:focus + label:before {
    border-color: get-color(input-fields, blue-paddl);
  }

  &:checked + label:before {
    background: url('~images/v2/icon--checkbox.svg') 50% 50% no-repeat;
  }
}

%checkbox-label {
  color: get-color(input-fields, blue-darker);
  font-size: get-input-value(label, font-size);
  display: flex;
  align-items: center;
  cursor: pointer;

  &:before {
    content: '';
    display: inline-block;
    margin-right: get-input-gutter-horizontal();
    width: 28px;
    height: 28px;
    border: 1px solid get-color(input-fields, light-blue);
    border-radius: get-input-value(checkbox, border-radius);
  }
}

%checkbox-message {
  color: get-color(input-fields, blue-grey);
  font-size: get-input-value(error-message, font-size);
}

%checkbox-message-error {
  color: get-color(input-fields, red);
}
