@import '~scss/mixins/screen-size';
@import '~scss/variables';

@include basecamp-page-loose-desktop-view() {
  .v2-page-content .v2-basecamp-page__status-panel {
    transition: $sidenav-transition;

    .--sidenav-contracted ~ & {
      left: $sidenav-width-contracted !important;
    }

    .--sidenav-expanded ~ & {
      left: $sidenav-width-expanded !important;
    }
  }
}

@media(min-width: 1120px) {
  .v2-modal__offset,
  .v2-modal-gradient-dark-blue__offset {
    transition: $sidenav-transition;

    .--sidenav-contracted ~ * & {
      margin-left: $sidenav-width-contracted !important;
    }

    .--sidenav-expanded ~ * & {
      margin-left: $sidenav-width-expanded !important;
    }
  }
}

@include screen-size-min-width(950px) {
  .modal__container {
    transition: $sidenav-transition;

    .--sidenav-contracted ~ * & {
      left: $sidenav-width-contracted !important;
    }

    .--sidenav-expanded ~ * & {
      left: $sidenav-width-expanded !important;
    }
  }
}

@include screen-size-sm() {
  .sidenav {
    transform: translate3d(0, 0, 0) !important;
    transition: none;
  }

  .form-actions,
  .v2-page-content .page__content .page__actions.is--top,
  .v2-page-content .page__content .page__actions.is--bottom,
  .navbar,
  .ghostnav,
  .page__map > div,
  .page__error,
  .v2-snack-bar,
  .conversationsView-footer {
    transition: $sidenav-transition;

    .--sidenav-contracted ~ & {
      left: $sidenav-width-contracted !important;
    }

    .--sidenav-expanded ~ & {
      left: $sidenav-width-expanded !important;
    }
  }

  .page__content,
  .v2-page-content,
  .v2-page-content .v2-basecamp-page__footer,
  .tour-guide__container {
    transition: $sidenav-transition;

    .--sidenav-contracted ~ & {
      margin-left: $sidenav-width-contracted !important;
    }

    .--sidenav-expanded ~ & {
      margin-left: $sidenav-width-expanded !important;
    }
  }

  .page__content:before { visibility: hidden !important; }
  .v2-page-content:before { visibility: hidden !important; }
}
