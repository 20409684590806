@import '~component-lib/swatches';

.svg-icon-slim-check {
  &__mark {
    fill: none;
    stroke: $white;
    stroke-width: 3px;
    stroke-dasharray: 1000;

    &--is-animated {
      stroke-dashoffset: 1000;
      animation-name: animationIconCheck;
      animation-duration: 0.9s;
      animation-delay: 1s;
      animation-fill-mode: forwards;

      @keyframes animationIconCheck {
        to {
          stroke-dashoffset: 0;
        }
      }

      &.svg-icon-slim-check__mark--with-short-delay {
        animation-duration: 1s;
        animation-delay: 0.2s;
      }
    }
  }
}
