// Swatches in 2020

// The color names & hex values should be the same as in Figma
// https://www.figma.com/file/f4zfDxo9HdUcurAeqwkHcDka/Paddl-Colors?node-id=0%3A1&viewport=296%2C451%2C0.2553546726703644

// The skin colors, $skin-1-light, $skin-4-dark etc., are not required to be added to frontend
// as they're only used for character artwork.
// https://www.figma.com/file/f4zfDxo9HdUcurAeqwkHcDka?node-id=0:1#27040345

$plum-paddl: #5320A3;

$plum-electric: #4D38E1;
$plum-electric-active: #6855E5;

$plum-dark: #441E80;
$plum-dark-active: #1F0145;

$blue-dark: #006DEF;
$new-blue: #1A91FF; // TODO: Rename $new-blue as $blue once the legacy one below is removed
$new-blue-light: #5EB2FF; // TODO: Rename $new-blue-light as $blue-light once the legacy one below is removed
$blue-lighter: #AED8FF;
$blue-lightest: #DFF0FF;

$bluegrey-d: #124F71;
$bluegrey: #6B9FB8;
$bluegrey-l: #C4D9E3;

$sec-purple-darker: #7B3DED;
$sec-purple-dark: #8E57F0;
$sec-purple: #A167FF;
$sec-purple-light: #C7A4FF;
$sec-purple-lighter: #E7D9FF;

$sec-blue-darker: #00B3DA;
$sec-blue-dark: #00CEEA;
$sec-blue: #00D8F5;
$sec-blue-light: #66E8F9;
$sec-blue-lighter: #BFF5FD;

$sec-yellow-darker: #F3A016;
$sec-yellow-dark: #FFC128;
$sec-yellow: #FFCF59;
$sec-yellow-light: #FFE29B;
$sec-yellow-lighter: #FFF3D5;

$sec-green-darker: #01BA99;
$sec-green-dark: #2BD09F;
$sec-green: #44DDA6;
$sec-green-light: #8FEBCA;
$sec-green-lighter: #D0F6E9;

$sec-red-darker: #D32859;
$sec-red-dark: #E33B6B;
// There's a bug in Figma
// Please ignore if you see the hex value of sec-red stated as #F75183 in the css column
// This is how the inconsistency looks like:
// https://drive.google.com/file/d/1uGff4iVpZHYDkg7SCcTP6y4oSJX9dsXX/view
$sec-red: #F65183;
$sec-red-light: #FF8BAE;
$sec-red-lighter: #FDD4E0;

$sec-orange-darker: #F05C42;
$sec-orange-dark: #FD7152;
$sec-orange: #FF8267;
$sec-orange-light: #FFB4A4;
$sec-orange-lighter: #FFE0D9;

$charcoal: #2D2936;
$new-grey-dark: #5B5776; // Naming inconsistency https://drive.google.com/open?id=1k2NUMMa5vo1e3mdp1d6DeDAycT7kcn5P
$new-gray: #797596; // Naming inconsistency https://drive.google.com/open?id=1p4pmyL9Zb706fnt5CQAPHtgv-IcDKrW9
$new-grey-light: #AEACC0; // Naming inconsistency https://drive.google.com/open?id=17oRyoowXgWDIbmI7Wlqsjwga_yjYrXat
$grey-lighter: #E4E3EA; // Naming inconsistency https://drive.google.com/open?id=1AXrNnDtPnTBL-zew2GNZGuu_PqtSARmI

// swatches before 2020
$white: #FFFFFF;

$red:   #EF5350;
$red-d: #9E0300;

$orange-xl: #FFE3CE;
$orange-l:  #F6BB8B;
$orange:    #FF720C;
$orange-d:  #D6610B;

$yellow-l: #FDDD85;
$yellow:   #FEBF0E;
$yellow-d: #EBAB00;

$purple-xl: #E4D9EF;
$purple-l:  #BEAAE2;
$purple:    #7E57C2;
$purple-d:  #311B92;

$blue-xxl: #E0F5FE;
$blue-xl:  #B1E5FC;
$blue-l:   #6BC3FC;
$blue:     #089BFB;
$blue-d:   #007BCB;

$bluegrey-xxl: #FAFBFC;
$bluegrey-xl:  #EAF4F7;

$green-l: #A5FEDE;
$green:   #14E3BB;
$green-d: #13BB9D;

$grey-xl: #BCC0C3;
$grey-l:  #95A9B3;
$grey:    #324048;
$grey-d:  #1C1F21;

// Branded colors
$wsu-red-l: #e86f83;
$wsu-red:   #9d2236;
$wsu-red-d: #63171b;

// Legacy colors
$grey-light:  #eef1f3;
$legacy-grey: #b3bdbb;
$grey-dark:   #324048;
$legacy-blue: #03a9f4;
$blue-light:  #f4fafe;
$blue-paddl:  #69d2f7;
$blue-grey:   #95a9b3;
$blue-darker: #124f71;

// Legacy status colors
$warning-yellow: #FEBF0E;
$danger-red:     #FF720C;
$primary-blue:   #69D2F7;
$inactive-gray:  #6B9FB8;
$success-green:  #10E7BE;

// Legacy page panel colors
$stat_number_gray: #6B9FB8;
$label_gray:       #95A9B3;
$divider_gray:     #DCE7EA;
