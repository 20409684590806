@import '~scss/components';
@import '~component-lib/swatches';

.button {
  white-space: nowrap;
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  background: none;
  border: none;
  text-align: center;
  outline: none;
  padding: 0 14px;
  height: 48px;
  line-height: 24px;
  position: relative;
  z-index: 10;
  border-radius: 2px;
  text-decoration: none;
  background: #fff;
  white-space: nowrap;
  font-weight: 500;
  font-style: normal;
  transition:
    opacity .15s ease-in-out,
    box-shadow .15s ease-in-out;

  &.has--shadow  {
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.15);
    &:hover {
      box-shadow: 0 1px 1px 0 rgba(0,0,0,0.15);
    }
  }
  &.is--wide     { padding: 7px 32px }
  &.is--centered { display: inline-block; }
  &.is--block    { display: block; width: 100%; }
  &.is--fixed    { min-width : 220px }
  &.is--primary  { background: #03A9F4 }
  &.is--opaque   { background: #d2d8da }
  &.is--secondary{ background: #ffffff }
  &.is--action   { background: #ff720c }
  &.is--danger   { background: #ef5350 }
  &.is--dark     { background: #7E57C2 }
  &.is--success  { background: $success-green }
  &.is--rounded {
    min-width: 48px;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-radius: 48px;
    box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
    transition: box-shadow .15s ease-in-out;

    &.is--small {
      width: 42px;
      height: 42px;
    }

    &:hover {
      box-shadow: 0px 0 0 8px rgba(0,0,0,0.05);
      opacity: 1;

      &.is--primary { box-shadow: 0px 0 0 8px rgba(3,169,244,0.2) }
      &.is--action  { box-shadow: 0px 0 0 8px rgba(255,114,12,0.2) }
    }
  }
  &.is--small {
    min-width: 28px;
    height: 28px;
    line-height: 22px;
  }
  &.is--loading {
    animation: pulse 2s infinite;
    cursor: progress;
  }
  &:active {
    opacity: .85;
    box-shadow: none;
  }
  &.is--centered {
    margin: auto;
  }
  &.is--completed {
    pointer-events: none;
    user-select: none;
    cursor: not-allowed;
    flex-direction: row;
    background: rgba(220, 231, 234, 0.2);
    box-shadow: inset 0 2px 2px 0 rgba(0,0,0,0.10);
  }
  &.is--mobile-link {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: transparent;
    text-align: right;
    padding: 0;
  }

  &:hover {
    opacity: .8;
  }
}

.button[disabled] {
  opacity: .2;
  pointer-events: none;
  user-select: none;
  cursor: not-allowed;
}

.button__icon {
  & > .icon {
    font-size: 26px;
    font-weight: 200;
    vertical-align: middle;
    display: inline-block;
    padding: 0;

    .is--small       & { font-size: 18px }
    .is--completed   & { color: #5AF2D5 }
    .is--mobile-link & { font-size: 32px; }
    .has--caret      & { font-size: 32px; }
  }

  .button__label + & { margin-left: 16px }
  .is--completed   & { margin: 0 -8px 0 16px }
  .has--caret      & { display: none; }
}

.button__icon {
  color: #6B9FB8;
  width: 26px;
  flex: 1 auto;

  .is--help &,
  .is--action &,
  .is--success &,
  .is--primary & {
    color: $white;
  }

  .has--caret &,
  .is--mobile-link &{
    padding-right: 8px;
  }
}

.button__title {
  font-size: 11px;
  font-weight: 500;
  padding: 7px 0;
  color: #95A9B3;

  .is--rounded & {
    position: absolute;
    bottom: -30px;
    width: 150%;
    left: -25%;
    text-align: center;
  }
  .is--help    & { color: #7E57C2; }
  .is--success & { color: $success-green }
}

.button__label {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  color: #4B88A6;
  display: block;
  width: 100%;
  text-align: center;

  .is--rounded & {
    padding: 0 20px 0 6px;
    font-weight: 400;
  }
  .is--primary    & { color: #fff; }
  .is--action     & { color: #fff; }
  .is--dark       & { color: #fff; }
  .is--danger     & { color: #fff; }
  .is--success    & { color: #fff; }
  .is--completed  & { color: #5AF2D5; }
  .has--score     & { color: #124F71; }
}

@include screen-size-xs() {
  .button.is--mobile-link { display: flex; }
  .is--rounded .button__label { display: none; }
  .is--rounded .button__label + .button__icon { margin-left: 0 }

  .button.has--caret {
    background: none;
    box-shadow: none !important;
    flex: 1 auto;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    text-align: right;
    padding: 0;

    .button__label { display: none }
    .button__icon  { display: block }
  }
}

@keyframes pulse {
  0%   { opacity: .6 }
  20%  { opacity: .3 }
  80%  { opacity: .3 }
  0%   { opacity: .6 }
}
