@import '~css/v2/abstracts/functions/get-input-functions';
@import '~css/v2/abstracts/functions/get-color-functions';
@import '~css/v2/abstracts/functions/get-font-weight-functions';
@import '~css/v2/abstracts/placeholders/_input-fields-common';

/**
* Input text:
* 1. placeholder class for input text
* 2. placeholder class for input text label
* 3. placeholder class for input text message (div below input)
*/

%input-text-multiline {
  padding: 12px 0;
  overflow: hidden;
  resize: none;
  border-radius: 0;
  background: none;
  border: none;
  display: block;
  outline: none;
  font-weight: get-font-weight(sofia-pro, light);
  font-size: get-input-value(input-text-multiline, font-size);
  border-bottom: 1px solid get-color(input-fields, grey-blue);
  transition: border .2s ease-in-out;
  width: 100%;
  color: get-color(input-fields, blue-darker);

  &::placeholder {
    color: get-color(input-fields, light-grey);
  }

  &:focus {
    border-color: get-color(input-fields, dodger-blue);
  }

  &:disabled {
    @extend %input-fields__disabled;

    -webkit-text-fill-color: get-color(input-fields, hippie-blue);
  }
}

%input-text-multiline-label {
  font-size: get-input-value(label, font-size);
  color: get-color(input-fields, blue-darker);
  font-weight: get-font-weight(sofia-pro, medium);
  padding-bottom: (get-input-gutter-vertical() / 2);
  text-align: left;
  position: relative;
}

%input-text-multiline-message {
  color: get-color(input-fields, grey-blue);
  font-size: get-input-value(error-message, font-size);
  text-align: right;
}

/**
* Input text modifiers:
* 1. placeholder class for input text error modifier
* 2. placeholder class for input text error message modifier
*/

%input-text-multiline-error {
  border-bottom: 1px solid get-color(input-fields, red);
}

%input-text-multiline-message-error {
  color: get-color(input-fields, red);
}

%input-text-multiline-message-hint {
  color: get-color(input-fields, light-grey);
}
