/**
* sass map structure: $font-weight-map
* keeps the hexadecimal values for font-weight property used in v2 components.
* Each main keys represents a font
* main keys: sofia-pro
* resource: https://typekit.com/fonts/sofia
*/

$font-weight-map: (
  sofia-pro: (
    ultra-light: 100,
    extra-light: 200,
    light:       300,
    regular:     400,
    medium:      500,
    semi-bold:   600,
    bold:        700,
    black:       900,
  )
);
