@import '~scss/components';

.input__email,
.input__text,
.input__number,
.input__password {
  border-radius: 0;
  background: none;
  border: none;
  display: block;
  outline: none;
  font-weight: 300;
  border-bottom: 1px solid #DCE7EA;
  transition: border .2s ease-in-out;

  @include screen-size-xs() {
    height: 40px;
    font-size: 16px;
  }

  @include screen-size-sm() {
    height: 44px;
    font-size: 18px;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }

  &::placeholder {
    color: #A6C5D4;
  }

  &.is--large {
    padding: 5px 0 6px;

    @include screen-size-xs() {
      font-size: 16px;
    }

    @include screen-size-sm() {
      font-size: 26px;
    }
  }

  &.is--short {
    text-align: center;
    width: 56px;
    font-weight: 500;
    font-size: 22px;
    padding: 4px 0 7px;

    @include screen-size-xs {
      width: 100%;
    }

    .has--error & {
      color: #EF5350;
    }
  }

  &:focus          { border-color: #69D2F7 }
  &.is--primary    { color: #FF900C }
  &.is--secondary  { color: #0288D1 }
  .has--error &    { border-color: #EF5350 }
  &.is--legacy     { width: 100% }
  .is--secondary & { border-color: #34D5A9 }
  &[disabled]      {
    color: #D2D8DA;
    -webkit-text-fill-color:#D2D8DA;
    -webkit-opacity: 1;
  }
}

.input__text__addon {
  display: flex;
}

.input__text__prefix,
.input__text__postfix {
  white-space: nowrap;
  font-size: 16px;
  color: #124F71;
  line-height: 44px;

  .is--disabled & { color: #A6C5D4 }

  strong {
    font-weight: 500;
  }
}

.input__text__placeholder {
  line-height: 44px;
  color: #6B9FB8;
  font-size: 16px;
}
