@import '~scss/variables';

// Very small screen sizes(smartphones), see $screen-xxs variable at scss/_variables.scss
@mixin screen-size-xxs() {
  @media (max-width: #{$screen-xxs}) {
    @content;
  }
}

// Devices(smartphones, tablets), see $screen-xs variable at scss/_variables.scss
@mixin screen-size-xs() {
  @media (max-width: #{$screen-xs}) {
    @content;
  }
}

// Desktop and larger screens, see $screen-sm variable at scss/_variables.scss
@mixin screen-size-sm() {
  @media (min-width: #{$screen-sm}) {
    @content;
  }
}

// Basecamp Page Mobile View
@mixin basecamp-page-mobile-view() {
  @media (max-width: #{$basecamp-page-mobile-view-max-width}) {
    @content;
  }
}

// Basecamp Page Compact Desktop View
@mixin basecamp-page-compact-desktop-view() {
  @media (min-width: #{$basecamp-page-mobile-view-max-width + 1}) and (max-width: #{$basecamp-page-loose-desktop-view-min-width - 1}) {
    @content;
  }
}

// Basecamp Page Loose Desktop View
@mixin basecamp-page-loose-desktop-view() {
  @media (min-width: #{$basecamp-page-loose-desktop-view-min-width}) {
    @content;
  }
}

// Timeline Activities Compact View
@mixin timeline-activities-compact-view() {
  @media (max-width: #{$timeline-activities-loose-view-min-width - 1}) {
    @content;
  }
}

// Timeline Activities Loose View
@mixin timeline-activities-loose-view() {
  @media (min-width: #{$timeline-activities-loose-view-min-width}) {
    @content;
  }
}

// Custom min-width cases
@mixin screen-size-min-width($screen-size) {
  @media (min-width: #{$screen-size}) {
    @content;
  }
}
