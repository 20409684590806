@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

.swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing;
}

@keyframes notFoundFadeIn {
  0%   { transform: translateY(10px); opacity: 0; }
  100% { transform: translateY(0);    opacity: 1; }
}

.notFoundFadeIn {
  -webkit-animation-name: notFoundFadeIn;
  animation-name: notFoundFadeIn;
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -7%, 0);
    transform: translate3d(0, -7%, 0);
    visibility: visible;
  }

  50% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, -7%, 0);
    transform: translate3d(0, -7%, 0);
  }
}

.slideInDown {
  -webkit-animation: slideInDown 6s linear infinite;
  animation: slideInDown 6s linear infinite;
}

@keyframes backgroundScroll {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -400px 0;
  }
}

.backgroundScroll {
  -webkit-animation: backgroundScroll 20s linear infinite;
  animation: backgroundScroll 20s linear infinite;
}
