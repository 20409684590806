/**
* sass map structure: $color-map
* keeps the hexadecimal values for color property used in v2 components.
* Each main keys represents a section or component
* main keys: neutrals || typography || buttons || input-fields || modals
* resource: http://veli.ee/colorpedia/ for hex color names
*/

$color-map: (
  neutrals: (
    white: #fff,
    black: #324048,
    iron: #d2d8da,
    light-blue-grey: #eaf4f7,
    light-blue: #089BFB,
    lochmara-dark: #007BCB,
  ),
  typography: (
    blue-darker: #124f71,
    light-blue-grey: #95a9b3,
    hippie-blue: #6b9fb8,
  ),
  buttons: (
    white: #fff,
    bright-turquoise: #14e3bb,
    dark-turquoise: #21CD9E,
    blue: #089BFB,
    blue-paddl: #69d2f7,
    hippie-blue: #6b9fb8,
    cerulean: #03a9f4,
    lochmara: #0288d1,
    shamrock: #34d5a9,
    grey-dark: #324048,
    grey: #b3bdbb,
    light-blue-grey: #eaf4f7,
    botticelli: #C4D9E3,
    orange: #ff720c,
    red: #EF5350,
    aqua-haze: #f0f4f7,
  ),
  input-fields: (
    black: #000000,
    blue: #03a9f4,
    grey-blue: #dce7ea,
    blue-paddl: #69d2f7,
    blue-darker: #124f71,
    light-blue: #a6c5d4,
    light-grey: #D2D8DA,
    blue-grey: #95a9b3,
    red: #ef5350,
    light-blue-grey: #eaf4f7,
    grey-shadow: rgba(0,0,0,0.12),
    dodger-blue: #37b1ff,
    hippie-blue: #6b9fb8,
  ),
  tags: (
    white: #fff,
    black: #000,
    dodger-blue: #37b1ff,
    azure-radiance: #0095f5,
    hippie-blue: #6b9fb8,
  ),
  panels: (
    white: #fff,
    blue-darker: #124f71,
    gull-gray: #95a9b3,
    cerulean: #03a9f4,
    light-blue-grey: #eaf4f7,
    bright-turquoise: #14e3bb,
    french-pass: #b1e5fc,
  ),
  timeline: (
    blue-darker: #124f71,
    blue-paddl: #69d2f7,
    blue-grey: #95a9b3,
    hippie-blue: #6b9fb8,
    dodger-blue: #37b1ff,
    cerulean: #03a9f4,
    catskill-white: #f0f5f8,
    french-pass: #b1e5fc,
    link-water: #f2f7fc,
    blue-l: #6bc3fc,
  ),
  profile: (
    white: #fff,
    blue-paddl: #69d2f7,
    cerulean: #03a9f4,
    pigeon-post: #a6c5d4,
    alto: #dadada,
    gull-gray: #95a9b3,
    blue-darker: #124f71,
    dodger-blue: #37b1ff,
    amber: #febf0e,
    athens-gray: #fafbfc,
    link-water: #f2f7fc,
    catskill-white: #f0f5f8,
    azure-radiance: #0095f5,
    light-blue: #089BFB,
    hippie-blue: #6b9fb8,
  ),
);
