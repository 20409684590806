.font-size-12px { font-size: 12px; }
.font-size-14px { font-size: 14px; }
.font-size-16px { font-size: 16px; }
.font-size-18px { font-size: 18px; }
.font-size-20px { font-size: 20px; }
.font-size-22px { font-size: 22px; }
.font-size-24px { font-size: 24px; }
.font-size-26px { font-size: 26px; }
.font-size-32px { font-size: 32px; }
.font-size-36px { font-size: 36px; }
.font-size-40px { font-size: 40px; }
