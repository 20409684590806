@import '~component-lib/swatches';
@import '~css/v2/abstracts/functions/get-input-functions';
@import '~css/v2/abstracts/functions/get-color-functions';
@import '~css/v2/abstracts/functions/get-font-weight-functions';
@import '~css/v2/abstracts/placeholders/_input-fields-common';

/**
* Input text:
* 1. placeholder class for input text
* 2. placeholder class for input text label
* 3. placeholder class for input text message (div below input)
*/

%input-text-single-line {
  border-radius: 0;
  background: none;
  border: none;
  display: block;
  outline: none;
  font-weight: get-font-weight(sofia-pro, light);
  height: (get-input-gutter-vertical() * 3);
  border-bottom: 1px solid get-color(input-fields, grey-blue);
  transition: border .2s ease-in-out;
  width: 100%;
  color: get-color(input-fields, blue-darker);

  &::placeholder {
    color: get-color(input-fields, light-grey);
  }

  &:focus {
    border-color: get-color(input-fields, dodger-blue);
  }

  &:disabled {
    @extend %input-fields__disabled;
  }
}

%input-text-single-line--default-font-size {
  font-size: get-input-value(input-text-single-line--default, font-size);
}

%input-text-single-line--small-font-size {
  font-size: get-input-value(input-text-single-line--small, font-size);
}

%input-text-single-line-input-wrapper {
  align-items: baseline;
}

%input-text-single-line-input-copy {
  line-height: 1;
  font-size: 24px;
  font-weight: get-font-weight(sofia-pro, light);
  color: get-color(timeline, blue-darker);
}

%input-text-single-line-label {
  font-size: get-input-value(label, font-size);
  color: get-color(input-fields, blue-darker);
  font-weight: get-font-weight(sofia-pro, medium);
  padding-bottom: (get-input-gutter-vertical() / 2);
  text-align: left;
  position: relative;
}

%input-text-single-line-message {
  color: get-color(input-fields, grey-blue);
  font-size: get-input-value(error-message, font-size);
  text-align: right;
}

/**
* Input text modifiers:
* 1. placeholder class for input text error modifier
* 2. placeholder class for input text error message modifier
*/

%input-text-single-line-error {
  border-bottom: 1px solid get-color(input-fields, red);
}

%input-text-single-line-message-error {
  color: get-color(input-fields, red);
}

%input-text-single-line-message-warning {
  color: $yellow-d;
}

%input-text-single-line-message-hint {
  color: get-color(input-fields, light-grey);
}

%input-text-single-line-message-success {
  color: $green;
}
