@import '~component-lib/swatches';

$modal-content-width: 600px;

// Page wrapper settings
$page-content-width: 720px;

// Page wrapper children settings
$page-wrapper-children-padding-1-val: 16px;

$navbar-height-xs: 60px;
$navbar-height-sm: 68px;

// Sidenav
$sidenav-width-expanded:   240px;
$sidenav-width-contracted: 72px;
$sidenav-transition-speed: 0.35s;
$sidenav-transition:       all #{$sidenav-transition-speed} ease-in-out;
$sidenav-transition-delay: #{$sidenav-transition-speed / 2};

// New navbar(used in the new regstration pages)
$v2-navbar-login-height:          64px;
$v2-navbar-padding-x:             16px;
$v2-navbar-login-bg:              $white;
$v2-navbar-login-box-shadow:      0px 2px 2px rgba(0, 0, 0, .1);
$space-between-anchor-and-navbar: 32px;

// Generic Breakpoints
$screen-xxs: 480px;
$screen-xs:  768px;
$screen-sm:  $screen-xs + 1;

// Breakpoint for Basecamp page
$basecamp-page-footer-height:            112px;
$basecamp-page-status-panel-fixed-width: 448px;
$basecamp-page-content-panel-min-width:  720px;
$basecamp-page-content-panel-min-gutter: 16px;
$basecamp-page-mobile-view-max-width:    768px;

$basecamp-page-loose-desktop-view-min-width:
  $sidenav-width-expanded +
  $basecamp-page-status-panel-fixed-width +
  $basecamp-page-content-panel-min-width +
  $basecamp-page-content-panel-min-gutter * 2;

// Breakpoint for .v2-generics-timeline
$calendar-vertical-width:              44px;
$calendar-vertical-horizontal-padding: 8px;

$calendar-vertical-broad-view-shift:
  $calendar-vertical-width +
  $calendar-vertical-horizontal-padding * 2;

$timeline-activities-loose-view-min-width:
  $sidenav-width-expanded +
  $page-content-width +
  $calendar-vertical-broad-view-shift * 2;
