@import '~scss/variables';
@import '~scss/mixins/screen-size';

@mixin page-config-x($space-x: 8px, $content-max-width: $page-content-width) {
  width: 100%;
  max-width: $content-max-width + $space-x * 2;
  padding-right: $space-x;
  padding-left: $space-x;
  margin-right: auto;
  margin-left: auto;
}

@mixin page-config-y($space-top: 16px, $space-bottom: 32px) {
  padding-top: $space-top;
  padding-bottom: $space-bottom;

  @include screen-size-sm {
    padding-top: $space-top * 2;
    padding-bottom: $space-bottom * 2;
  }
}

@mixin page-config {
  @include page-config-x;
  @include page-config-y;
}

@mixin public-page-config {
  $content-max-width: 640px;
  $space-x: 16px;
  $space-top: 48px;
  $space-bottom: $space-top * 2;

  // styles for space in x-axis
  width: 100%;
  max-width: $content-max-width + $space-x * 2;
  padding-right: $space-x;
  padding-left: $space-x;
  margin-right: auto;
  margin-left: auto;

  // styles for space in y-axis
  padding-top: $space-top;
  padding-bottom: $space-bottom;

  @include screen-size-sm {
    padding-top: $space-top * 2;
    padding-bottom: $space-bottom * 2;
  }
}
