.not-found,
.public-not-found {
  color: #324048;
  margin: 0;
  padding: 0;
  background: #ecf9fe;
  background: linear-gradient(to bottom, #ecf9fe 0%, #f2fbfe 100%);
  min-height: 100vh;
  overflow: hidden;

  h1 {
    font-size: 48px;
    font-weight: 500;
    margin-bottom: 15px;
  }

  h2 {
    font-size: 26px;
    font-weight: 200;
    color: #95A9B3;
  }

  p, pre {
    font-size: 16px;
    font-weight: 200;
    line-height: 1.45;
    margin-top: 2.4em;
    max-width: 500px;
    padding: 0 20px;
    -webkit-font-smoothing: antialiased;
  }

  .container {
    min-height: 80%;
    margin: 0 auto -300px;
    text-align: center;
    padding-top: 10%;
  }

  .container > * {
    margin-left: auto;
    margin-right: auto;
  }

  .illu, .push {
    height: 300px;
  }

  .waves, .water, .boat-wrapper, .bg {
    position: fixed;
    bottom: 0;
  }

  .waves, .water {
    width: 100%;
    z-index: 2;
  }

  .waves {
    height: 5px;
    bottom: 80px;
    background-repeat: repeat-x;
  }

  .water {
    height: 80px;
    background: #4BB9E8;
  }

  .boat-wrapper {
    left: 50%;
    z-index: 1;
  }

  .boat {
    width: 320px;
    height: 280px;
    position: relative;
    left: -54%;
    margin-top: 26px;
    background-repeat: no-repeat;
  }

  .bg {
    width: 5000px;
    height: 250px;
    background-size: 25%;
    background-repeat: repeat-x;
    -webkit-filter: blur(1.5px);
    filter: blur(1.5px);
  }

  .animated {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .animated.delayed {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
  }
}
