@import '~css/v2/abstracts/functions/get-input-functions';
@import '~css/v2/abstracts/functions/get-color-functions';
@import '~css/v2/abstracts/functions/get-font-weight-functions';
@import '~css/v2/abstracts/placeholders/_input-fields-common';

/**
* select:
* 1. placeholder class for select
* 2. placeholder class for select label
* 3. placeholder class for select message (div below input)
*/

%select {
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  background: none;
  border: none;
  display: block;
  outline: none;
  font-weight: get-font-weight(sofia-pro, light);
  height: (get-input-gutter-vertical() * 3);
  border-bottom: 1px solid get-color(input-fields, grey-blue);
  transition: border .2s ease-in-out;
  color: get-color(input-fields, blue-darker);
  width: 100%;

  &::-ms-expand {
    display: none;
  }

  &:invalid {
    color: get-color(input-fields, light-grey);
  }

  // In Firefox, the color of the options is inherited from class select.
  // Therefore, it's blue-darker when valid and light-grey when invalid.
  // In order to make the color fixed, we need to specify it here.
  & > option {
    color: get-color(input-fields, blue-darker);
  }

  &:focus {
    border-color: get-color(input-fields, dodger-blue);
  }

  &:disabled {
    @extend %input-fields__disabled;
  }
}

%select--default-font-size {
  font-size: get-input-value(select--default, font-size);
}

%select--small-font-size {
  font-size: get-input-value(select--small, font-size);
}

%select-label {
  font-size: get-input-value(label, font-size);
  color: get-color(input-fields, blue-darker);
  font-weight: get-font-weight(sofia-pro, medium);
  padding-bottom: (get-input-gutter-vertical() / 2);
  text-align: left;
  position: relative;
}

%select-message {
  color: get-color(input-fields, grey-blue);
  font-size: get-input-value(error-message, font-size);
  text-align: right;
}

/**
* selectmodifiers:
* 1. placeholder class for select error modifier
* 2. placeholder class for select error message modifier
*/

%select-error {
  border-color: get-color(input-fields, red);
}

%select-message-error {
  color: get-color(input-fields, red);
}
