/**
* sass map structure: $gutter-map
* 1rem === 16px (our font-size base)
* gutter to use in paddings and margins in v2 components
* top, bottom values for paddings/margins use gutter-v
* right left values for paddings/margins use gutter-h
*/

$gutter-map: (
  gutter-v: 1rem,
  gutter-h: 1rem,
);
