@import '~component-lib/swatches';
@import '~css/v2/abstracts/functions/get-input-functions';
@import '~css/v2/abstracts/functions/get-color-functions';
@import '~css/v2/abstracts/functions/get-font-weight-functions';

/**
* Input text:
* 1. placeholder class for input text
* 2. placeholder class for input text label
* 3. placeholder class for input text message (div below input)
*/

%input-text-dropdown {
  border-radius: 0;
  background: none;
  border: none;
  display: block;
  outline: none;
  font-weight: get-font-weight(sofia-pro, light);
  height: (get-input-gutter-vertical() * 3);
  border-bottom: 1px solid get-color(input-fields, grey-blue);
  transition: border .2s ease-in-out;
  width: 100%;
  color: get-color(input-fields, blue-darker);

  &::placeholder{
    color: get-color(input-fields, light-grey);
  }

  &:focus {
    border-color: get-color(input-fields, dodger-blue);
  }
}

%input-text-dropdown--default-font-size {
  font-size: get-input-value(input-text-dropdown--default, font-size);
}

%input-text-dropdown--small-font-size {
  font-size: get-input-value(input-text-dropdown--small, font-size);
}

%input-text-dropdown-label {
  font-size: get-input-value(label, font-size);
  color: get-color(input-fields, blue-darker);
  font-weight: get-font-weight(sofia-pro, medium);
  padding-bottom: (get-input-gutter-vertical() / 2);
  text-align: left;
  position: relative;
}

%input-text-dropdown-message {
  color: get-color(input-fields, grey-blue);
  font-size: get-input-value(error-message, font-size);
  text-align: right;
}

/**
* Input text modifiers:
* 1. placeholder class for input text error modifier
* 2. placeholder class for input text error message modifier
*/

%input-text-dropdown-error {
  border-bottom: 1px solid get-color(input-fields, red);
}

%input-text-dropdown-message-error {
  color: get-color(input-fields, red);
}

%input-text-dropdown-message-warning {
  color: $yellow-d;
}

/**
* input-text dropdown list
* 1. placeholder class for input text with list (div below input)
*/

%input-text-dropdown-list {
  margin: 0;
  padding: 0;
  position: relative;
  background: $bluegrey-xxl;
  box-shadow: 0 2px 4px 0 rgba(get-color(input-fields, black),0.12);
  z-index: 10;
}

%input-text-dropdown-list-item {
  background: $bluegrey-xxl;
  list-style: none;
  border-bottom: 1px solid get-color(input-fields, light-blue-grey);
  margin:0;
  padding: get-input-gutter-vertical() get-input-gutter-horizontal();
  font-size: 18px;
  font-weight: get-font-weight(sofia-pro, extra-light);
  color: get-color(input-fields, blue-darker);
  cursor: pointer;
}
