@import '~component-lib/swatches';

%theme__activity-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 152px;

  padding: 24px;
  border-radius: 8px;
  background-color: $white;
  border: 1px solid $white;

  &--default-state {
    border-color: $bluegrey-xl;
  }

  &--active-state {
    border-color: $bluegrey-l;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
}

%theme__white-card {
  border-radius: 8px;
  background-color: $white;
  box-shadow: 0 4px 3px -2px rgba(0,0,0,0.08);
}
