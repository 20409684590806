.markdown {
  overflow: hidden;
  text-overflow: ellipsis;

  h1,
  h2,
  h3,
  p,
  ul,
  li {
    color: #324048 !important;
    font-weight: 200 !important;
    line-height: 1.5 !important;
  }

  p,
  ul,
  li {
    font-size: 16px !important;
  }

  ul {
    margin: 0;
    padding-bottom: 16px;
  }
}
