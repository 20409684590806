/**
* sass map structure: $button-gutter-map
* 1rem === 16px (our font-size base)
* gutter to use in paddings and margins specifically for v2/buttons components
* top, bottom values for paddings/margins use gutter-v
* right left values for paddings/margins use gutter-h
*/

$button-gutter-map: (
  gutter-v: 1rem,
  gutter-h: 1rem,
);


/**
* $button-values-map
* keeps values for css properties in button components:
* button-type: base-button, circle-button, square-button
*/

$button-values-map: (
  base-button: (
    border-radius: 2px,
  ),
  circle-button: (
    border-radius: 50%,
  )
);
