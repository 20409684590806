@mixin activity-card-heading-bg-img($category-artwork-url) {
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    width: 212.25px;
    height: 212.25px;
    opacity: .12;
    transform: rotate(-20deg);
    background: url($category-artwork-url) no-repeat;
    background-size: cover;
    position: absolute;
    top: -22px;
    left: -6px;
  }
}
