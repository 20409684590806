$joyride-color: #7E57C2 !default;
$joyride-zindex: 9999 !default;
$joyride-overlay-color: rgba(#95A9B3, 0.5) !default;

$joyride-beacon-color: $joyride-color !default;
$joyride-beacon-size: 36px !default;

$joyride-hole-border-radius: 6px !default;
$joyride-hole-shadow: 0 0 20px rgba(#95A9B3, 0.8) !default;

$joyride-tooltip-animation: joyride-tooltip 0.2s forwards;
$joyride-tooltip-animation-timing: cubic-bezier(0, 1.05, 0.55, 1.18);

$joyride-tooltip-arrow-size: 28px !default;
$joyride-tooltip-bg-color: #fff !default;
$joyride-tooltip-border-radius: 4px !default;
$joyride-tooltip-color: #324048 !default;
$joyride-tooltip-font-size: 15px !default;
$joyride-tooltip-padding: 24px !default;
$joyride-tooltip-width: (290px, 320px, 400px) !default;

$joyride-close: (color: rgba($joyride-tooltip-color, 0.15), size: 40px, top: 0, right: 0) !default;
$joyride-close-visible: true !default;

$joyride-header-color: $joyride-color !default;
$joyride-header-font-size: 18px !default;
$joyride-header-border-color: $joyride-color !default;
$joyride-header-border-width: 0 !default;

$joyride-button-bg-color: $joyride-color !default;
$joyride-button-color: #fff !default;
$joyride-button-radius: 2px !default;

$joyride-back-button-color: $joyride-color !default;
$joyride-skip-button-color: #ccc !default;

$joyride-tooltip-arrow-height: $joyride-tooltip-arrow-size / 2;
$joyride-tooltip-arrow-scale: ($joyride-tooltip-arrow-size / ($joyride-tooltip-arrow-size * 0 + 1)) / 16; // strip-units crazy hack

@function joyride-arrow($location: top, $bg-color: $joyride-tooltip-bg-color) {
  $height: $joyride-tooltip-arrow-height;
  $width: $joyride-tooltip-arrow-size;
  $rotate: '0';
  $arrow-color: '%23' + str-slice(#{$bg-color}, 2);

  @if $location == bottom {
    $rotate: '180 8 4';
  } @else if $location == left {
    $height: $joyride-tooltip-arrow-size;
    $width: $joyride-tooltip-arrow-height;
    $rotate: '270 8 8';
  } @else if $location == right {
    $height: $joyride-tooltip-arrow-size;
    $width: $joyride-tooltip-arrow-height;
    $rotate: '90 4 4';
  }

  @return "data:image/svg+xml,%3Csvg%20width%3D%22#{$width}%22%20height%3D%22#{$height}%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22#{$arrow-color}%22%20transform%3D%22scale%28#{$joyride-tooltip-arrow-scale}%29%20rotate%28#{$rotate}%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E";
}

.joyride {

  &-beacon {
    display: inline-block;
    height: $joyride-beacon-size;
    position: relative;
    width: $joyride-beacon-size;
    z-index: $joyride-zindex;

    &__inner {
      animation: joyride-beacon-inner 1.2s infinite ease-in-out;
      background-color: $joyride-beacon-color;
      border-radius: 50%;
      display: block;
      height: 50%;
      left: 50%;
      opacity: 0.7;
      position: relative;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
    }

    &__outer {
      animation: joyride-beacon-outer 1.2s infinite ease-in-out;
      background-color: rgba($joyride-beacon-color, 0.2);
      border: ($joyride-beacon-size / 18) solid $joyride-beacon-color;
      border-radius: 50%;
      display: block;
      height: 100%;
      left: 0;
      opacity: 0.9;
      position: absolute;
      top: 0;
      transform: translateY(-50%);
      transform-origin: center;
      width: 100%;
    }
  }

  &-overlay {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: $joyride-zindex;
    animation: joyride-overlay 0.2s forwards;
  }

  &-hole {
    border-radius: $joyride-hole-border-radius;
    box-shadow: 0 0 0 9999px $joyride-overlay-color, $joyride-hole-shadow;
    position: absolute;

    &.safari {
      box-shadow: 0 0 999px 9999px $joyride-overlay-color, $joyride-hole-shadow;
    }
  }

  &-tooltip {
    background-color: $joyride-tooltip-bg-color;
    border-radius: $joyride-tooltip-border-radius;
    color: $joyride-tooltip-color;
    cursor: default;
    opacity: 0;
    box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.10);
    padding: $joyride-tooltip-padding;
    transform: translate3d(0, 0, 0);
    width: nth($joyride-tooltip-width, 1);
    z-index: $joyride-zindex + 10;

    @media screen and (min-width: 480px) {
      width: nth($joyride-tooltip-width, 2);
    }

    @media screen and (min-width: 960px) {
      width: nth($joyride-tooltip-width, 3);
    }

    &--animate {
      animation: $joyride-tooltip-animation;
      animation-timing-function: $joyride-tooltip-animation-timing;
    }

    &__triangle {
      background-repeat: no-repeat;
      overflow: hidden;
      position: absolute;
    }

    &.bottom,
    &.bottom-left,
    &.bottom-right {
      margin-top: $joyride-tooltip-arrow-height;

      .joyride-tooltip__triangle {
        background-image: url(joyride-arrow(bottom));
        height: $joyride-tooltip-arrow-height;
        left: 50%;
        top: -($joyride-tooltip-arrow-height - 2);
        transform: translateX(-50%);
        width: $joyride-tooltip-arrow-size;
      }
    }

    &.top,
    &.top-left,
    &.top-right {
      margin-bottom: $joyride-tooltip-arrow-height;

      .joyride-tooltip__triangle {
        background-image: url(joyride-arrow(top));
        bottom: -($joyride-tooltip-arrow-height - 2);
        height: $joyride-tooltip-arrow-height;
        left: 50%;
        transform: translateX(-50%);
        width: $joyride-tooltip-arrow-size;
      }
    }

    &.bottom-left,
    &.top-left {
      .joyride-tooltip__triangle {
        left: 3%;
        transform: translateX(0);

        @media screen and (min-width: 480px) {
          left: 2%;
        }
      }
    }

    &.bottom-right,
    &.top-right {
      .joyride-tooltip__triangle {
        left: auto;
        right: 3%;
        transform: translateX(0);

        @media screen and (min-width: 480px) {
          right: 2%;
        }
      }
    }

    &.left {
      margin-right: $joyride-tooltip-arrow-height;

      .joyride-tooltip__triangle {
        background-image: url(joyride-arrow(left));
        height: $joyride-tooltip-arrow-size;
        right: -($joyride-tooltip-arrow-height - 2);
        width: $joyride-tooltip-arrow-height;
      }
    }

    &.right {
      margin-left: $joyride-tooltip-arrow-height;

      .joyride-tooltip__triangle {
        background-image: url(joyride-arrow(right));
        height: $joyride-tooltip-arrow-size;
        left: -($joyride-tooltip-arrow-height - 2);
        width: $joyride-tooltip-arrow-height;
      }
    }

    &__close {
      color: map-get($joyride-close, 'color');
      font-size: map-get($joyride-close, 'size');
      line-height: 24px;
      text-transform: uppercase;
      font-weight: 300;
      position: absolute;
      right: map-get($joyride-close, 'right');
      text-decoration: none;
      top: map-get($joyride-close, 'top');
      z-index: 10;

      @if $joyride-close-visible {
        display: block;
      } @else {
        display: none;
      }

      &:hover,
      &:focus {
        color: darken(map-get($joyride-close, 'color'), 40);
        outline: none;
        text-decoration: none;
      }

      &--header {
        right: 24px;
        top: 14px;
      }
    }

    &__header {
      border-bottom: $joyride-header-border-width solid $joyride-header-border-color;
      color: $joyride-header-color;
      font-size: $joyride-header-font-size;
      font-weight: 500;
      padding: 2px 0 7px;
      position: relative;
    }

    &__main {
      font-size: $joyride-tooltip-font-size;
      padding: 7px 0 24px;
    }

    &__footer {
      text-align: right;
    }

    &__button {
      &--primary {
        background-color: $joyride-button-bg-color;
        border-radius: $joyride-button-radius;
        color: $joyride-button-color;
        outline: none;
        padding: 6px 24px;
        font-weight: 500;
        text-decoration: none;
        transition: background-color 0.2s ease-in-out;

        &:active,
        &:focus,
        &:hover {
          background-color: lighten($joyride-button-bg-color, 6);
          color: $joyride-button-color;
          text-decoration: none;
        }

        span > span:last-child {
          font-weight: 300;
        }
      }

      &--secondary {
        color: $joyride-back-button-color;
        margin-right: 10px;
        outline: none;
      }

      &--skip {
        color: $joyride-skip-button-color;
        float: left;
        margin-right: 10px;
      }
    }

    &--standalone {
      .joyride-tooltip__main {
        padding-bottom: 0;
      }

      .joyride-tooltip__footer {
        display: none;
      }
    }
  }
}

@keyframes joyride-tooltip {
  0% {
    opacity: 0;
    transform: scale(0.6);
  }

  50% {
    opacity: .8;
  }

  100% {
    opacity: 1;
    transform: perspective(1px) scale(1);
  }
}

@keyframes joyride-beacon-inner {
  20% {
    opacity: 0.9;
  }

  90% {
    opacity: 0.7;
  }
}

@keyframes joyride-overlay {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
  }
}

@keyframes joyride-beacon-outer {
  0% {
    transform: scale(1);
  }

  45% {
    opacity: 0.7;
    transform: scale(0.75);
  }

  100% {
    opacity: 0.9;
    transform: scale(1);
  }
}