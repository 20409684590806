@mixin category-card-bg-img($category-artwork-url) {
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    width: 150.1px;
    height: 138.48px;
    opacity: .12;
    transform: rotate(-18.14deg);
    background: url($category-artwork-url) no-repeat;
    background-size: cover;
    position: absolute;
    top: -12px;
    right: 6px;
  }
}
