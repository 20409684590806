@import '~css/v2/abstracts/functions/get-input-functions';
@import '~css/v2/abstracts/functions/get-color-functions';
@import '~css/v2/abstracts/functions/get-font-weight-functions';

/**
* fieldset-label
* 2. placeholder class for fieldset-label
*/

// Todo: The space between label and radio buttons needs to be adjusted to match the other input fields.
%fieldset-label-of-radio-buttons {
  font-size: get-input-value(label, font-size);
  color: get-color(typography, blue-darker);
  font-weight: get-font-weight(sofia-pro, medium);
  padding-bottom: (get-input-gutter-vertical() / 2);
  text-align: left;
  position: relative;
}

%fieldset-label-of-select-fields {
  font-size: get-input-value(label, font-size);
  color: get-color(typography, blue-darker);
  font-weight: get-font-weight(sofia-pro, medium);
  text-align: left;
  position: relative;
}
