@import '~component-lib/swatches';

*, *:before, *:after {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  min-width: 320px;
}

body {
  font-feature-settings: "kern", "liga", "pnum";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $grey-dark;
  font-family: 'sofia-pro', 'Helvetica Neue', 'Helvetica', 'Roboto', 'Arial', 'sans-serif';
  font-size: 16px;
  line-height: 1.4;
  font-weight: 300;
}

h1 {
  font-size: 1.5em;
  font-weight: normal;
}

h2 {
  margin: 14px 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'sofia-pro', 'Helvetica Neue', 'Helvetica', 'Roboto', 'Arial', 'sans-serif';
  line-height: 1.2;
  margin: 14px 0;
  font-weight: 300;
}

b, strong {
  font-weight: bold;
}

img,
picture {
  margin: 0;
  max-width: 100%;
}

a {
  text-decoration: none;

  &:hover { text-decoration: underline; }
}

.intercom-launcher {
  right: 14px !important;
  bottom: 14px !important;
}

.intercom-launcher-button {
  width: 46px !important;
  height: 46px !important;
  box-shadow: none !important
}
