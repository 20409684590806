@mixin crop-line-height($line-height) {
  line-height: $line-height;

  &:before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((1 - #{$line-height}) * 0.5em);
  }

  &:after {
    content: '';
    display: block;
    height: 0;
    width: 0;
    margin-bottom: calc((1 - #{$line-height}) * 0.5em);
  }
}
