/**
* map name: $input-map
* responsability: keeps values for css properties in form input fields elements components:
* form element keys: checkbox, dropdown, input-text-multiline, input-text-dropdown, select
*/
$input-gutter-map: (
  gutter-v: 1rem,
  gutter-h: 1rem,
);

$input-values-map: (
  checkbox:                        ( border-radius: 4px ),
  dropdown:                        ( font-size: 24px    ),
  input-text-single-line--default: ( font-size: 24px    ),
  input-text-single-line--small:   ( font-size: 18px    ),
  input-text-multiline:            ( font-size: 18px    ),
  input-text-dropdown--default:    ( font-size: 24px    ),
  input-text-dropdown--small:      ( font-size: 18px    ),
  select--default:                 ( font-size: 24px    ),
  select--small:                   ( font-size: 18px    ),
  label:                           ( font-size: 16px    ),
  error-message:                   ( font-size: 14px    ),
);
