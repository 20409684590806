@mixin pulse-animation() {
  visibility: visible;

  animation-name: pulseAnimation;
  animation-duration: 1s;
  animation-iteration-count: 1;

  @keyframes pulseAnimation {
    from { transform: scale(0); }
    50% { transform: scale(1.50); }
    to { transform: scale(1); }
  }
}
