@import '~css/v2/abstracts/functions/get-button-functions';
@import '~css/v2/abstracts/functions/get-font-weight-functions';

/**
 * button:
 * 1. placeholder class for button
 * get-button-gutter-vertical = 1rem = 16px;
 * get-button-gutter-horizontal = 1rem = 16px;
 */

%button {
  height: get-button-gutter-vertical() * 3;
  line-height: (get-button-gutter-vertical() * 3) / 2;
  padding: (get-button-gutter-vertical() / 2) get-button-gutter-horizontal();
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  white-space: nowrap;
  position: relative;
  z-index: 10;
  background: none;
  outline: none;
  border: none;
  border-radius: get-button-value(base-button, border-radius);
  text-align: center;
  text-decoration: none;
  font-weight: get-font-weight(sofia-pro, medium);
  font-style: normal;
  transition:
    opacity .15s ease-in-out,
    box-shadow .15s ease-in-out;
}
